import React, { useState, useEffect } from "react";
import ReactJsonPrint from "react-json-print/src/react-json-print";
import styled from "styled-components";
import { useIframePostMessage } from "../../../utils/hooks/post-message";
import exampleJson from "./example.json";

const StyledWrapper = styled.div`
  width: 100%;
`;

export const ReactJsonPrintPage = () => {
  let width = 500;
  let height = 500;

  if (typeof window !== `undefined`) {
    width = window.innerWidth;
  }

  if (typeof document !== `undefined`) {
    height = document.body.getBoundingClientRect().height;
  }

  const [resizeMessage, setResizeMessage] = useState({
    description: "window resize",
    width,
    height,
  });

  const handleResize = () => {
    if (typeof document !== `undefined`) {
      const bodyRect = document.body.getBoundingClientRect();
      const { height } = bodyRect;
      setResizeMessage({
        ...resizeMessage,
        height,
      });
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    document.addEventListener("click", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("click", handleResize);
    };
  }, []);

  if (typeof window !== `undefined`) {
    useIframePostMessage(resizeMessage, window.parent);
  }

  return (
    <StyledWrapper>
      <ReactJsonPrint dataObject={exampleJson} expanded />
    </StyledWrapper>
  );
};

export default ReactJsonPrintPage;
